.misc-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.misc-wrapper .misc-inner {
  position: relative;
  max-width: 750px;
}
.misc-wrapper .brand-logo {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 2rem;
}
[dir] .misc-wrapper .brand-logo {
  margin: 0;
}
[dir=ltr] .misc-wrapper .brand-logo {
  left: 2rem;
}
[dir=rtl] .misc-wrapper .brand-logo {
  right: 2rem;
}
.misc-wrapper .brand-logo .brand-text {
  font-weight: 600;
}
.brand-logo svg {
  height: 28px;
  width: 40.95px;
}